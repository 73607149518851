<template>
  <v-container fluid class="pt-0 fill-height">
    <v-bottom-sheet
        right
        app
        v-model="filters.show_filter"
        disable-route-watcher
        persistent
        bottom
        width="900"
    >
      <v-card min-height="80vh" class="pa-2" style="overflow: hidden">
        <v-card-text style="height: calc(80vh - 64px); overflow: auto">
          <v-tabs color="success" style="height: 100%">
            <v-tab class="text-left justify-start">
              Основне
            </v-tab>
            <v-tab class="text-left justify-start">
              Адреса
            </v-tab>

            <v-tab-item class="pa-0 pt-2">
              <v-row class="pb-2">
                <v-col cols="6">
                  <date_input v-model="filters_copy.date_from" label="Дата від" :time="true" filled
                              :required="false"
                  />
                </v-col>
                <v-col cols="6">
                  <date_input v-model="filters_copy.date_to" label="Дата до" :time="true" filled
                              :required="false"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select multiple
                            v-model="filters_copy.person_types" label="Тип абонента"
                            :items="dispatcher_person_type_select" filled
                            color="grey" hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-select multiple
                            v-model="filters_copy.call_types" label="Тип дзвінка"
                            :items="dispatcher_call_type_select" filled
                            color="grey" hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-select multiple
                            v-model="filters_copy.priorities" label="Пріоритет"
                            :items="dispatcher_priority_select" filled
                            color="grey" hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-select multiple
                            v-model="filters_copy.statuses" label="Статус"
                            :items="dispatcher_status_select" filled
                            color="grey" hide-details
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="pa-0 pt-3">
              <v-row class="pb-2">
                <v-col cols="12" md="6" class="py-2">
                  <AddressElementSelect
                      label="Об’єднана громада" v-model="filters_copy.amalgamated_hromada_id"
                      filled select_type="amalgamated_hromada"
                  />
                </v-col>
                <v-col cols="12" md="6" class="py-2">
                  <AddressElementSelect
                      label="Населений пункт" v-model="filters_copy.city_id"
                      :parent_id="filters_copy.amalgamated_hromada_id" :use_parent_id="true"
                      filled select_type="city"
                  />
                </v-col>
                <v-col cols="12" class="py-2">
                  <AddressElementSelect
                      label="Район міста" v-model="filters_copy.city_area_id"
                      :parent_id="filters_copy.city_id" :use_parent_id="true"
                      filled select_type="city-area"
                  />
                </v-col>
                <v-col cols="12" class="py-2" v-if="filters_copy.city_area_id">
                  <AddressElementSelect
                      label="Вулиця" v-model="filters_copy.street_id"
                      :parent_id="filters_copy.city_area_id" :use_parent_id="true"
                      filled select_type="street-with-city-area"
                  />
                </v-col>
                <v-col cols="12" class="py-2" v-else>
                  <AddressElementSelect
                      label="Вулиця(-ці)" v-model="filters_copy.street_id"
                      :parent_id="filters_copy.city_id" :use_parent_id="true"
                      filled select_type="street"
                  />
                </v-col>
                <v-col cols="12" class="py-2">
                  <AddressElementSelect
                      label="Будинок(-ки)" v-model="filters_copy.building_id"
                      :parent_id="filters_copy.street_id" :use_parent_id="true"
                      filled select_type="building"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="filters_copy.building_number" label="Номер будинку"
                                filled hide-details color="grey"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="filters_copy.building_number" label="Номер квартири"
                                filled hide-details color="grey"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model.number="filters_copy.entrance" label="Під'їзд"
                                filled hide-details color="grey" v-integer
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model.number="filters_copy.floor" label="Поверх"
                                filled hide-details color="grey" v-integer
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions style="background-color: white; height: 64px">
          <v-spacer></v-spacer>
          <v-btn depressed text color="secondary darken-1" @click.stop="acceptFilter"
                 class="button-accept">
            <v-icon left>mdi-content-save</v-icon>
            OK
          </v-btn>
          <v-btn depressed text tile color="teal" @click="clearFilters">
            <v-icon left>mdi-filter-remove</v-icon>
            Очистити
          </v-btn>
          <v-btn depressed text tile color="grey" @click="closeModal">
            <v-icon left>mdi-close</v-icon>
            Відмінити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    <MainModal
        :main="{ component: 'DispatcherCall', title: 'Виклик у диспетчерську' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected)"
        :full_screen="true"
        :modal="show_dialog"
        @updateItemModal="afterModalClose"
    />
    <list_modal
        :dialog="list_dialog.dialog"
        :list_name="list_dialog.name"
        :list_group_name="list_dialog.group"
        :hierarchy="list_dialog.hierarchy"
        :title="list_dialog.title"
        :element_title="list_dialog.element_title"
        :document="list_dialog.document"
        :full_screen="list_dialog.full_screen"
        :hide_accounting="list_dialog.hide_accounting"
        :alternative_header_name="list_dialog.alternative_header_name"
        @close="closeListDialog"
        class="d-print-none"
    />

    <v-row justify="center" style="display: flex; flex-direction: column; height: 100%">
      <v-col cols="12" style="flex: 0">
        <v-card rounded style="border: none" elevation="2" class="mt-2">
          <v-toolbar tile elevation="0" outlined dense style="border: none;" class="pl-2">
            <v-toolbar-items class="shrink remove-padding">
              <v-row class="pa- ma-0">
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                      Довідники
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item
                        v-for="d in directories"
                        :key="d.list_name"
                        class="menu-item"
                        @click.prevent="openListDialog(
                                                       d.list_name,
                                                       d.list_group_name,
                                                       d.hierarchy,
                                                       d.list_title,
                                                       d.element_title,
                                                       false,
                                                       d.full_screen || false
                                                      )"
                    >
                      <v-list-item-icon>
                        <v-icon size="22" color="success">
                          {{ d.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ d.list_title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider vertical/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="teal" class="mr-2">mdi-chart-bar</v-icon>
                      Звіти
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'dispatcher_calls_history' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Історія викликів диспетчерської</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'dispatcher_calls_stats' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Статистика викликів диспетчерської</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </v-toolbar-items>
            <v-spacer/>
            <v-btn small depressed color="success" @click="openCreateDialog" height="32" class="mr-3">
              <v-icon left>mdi-plus</v-icon>
              Новий виклик
            </v-btn>
            <v-text-field dense hide-details color="grey" v-model="search_text"
                          class="mr-3" @input="doSearch" append-icon="mdi-close"
                          prepend-icon="mdi-magnify"
                          @click:append="clearSearch"
                          style="max-width: 380px"
            />
            <v-btn small depressed height="32" @click="openFilterDialog">
              <v-icon color="success">
                mdi-filter-cog-outline
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <v-card style="height: 100%">
          <v-card-text style="height: 100%" class="pa-2">
            <v-data-table
                :headers="tableHeader"
                :items="items"
                no-data-text="Дані відсутні. Жодного запису не виявлено"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
                class="custom-table custom-table-1 dispatcher-call-table"
                @click:row="onItemClick"
            >
              <template v-slot:item.call_date="{ item }">
                <span>
                  {{ item.call_date | formatDate('DD.MM.YYYY') }}
                </span>
              </template>
              <template v-slot:item.flat_owner="{ item }">
                <span style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.flat_owner }}
                </span>
              </template>
              <template v-slot:item.workers_table_text="{ item }">
                <span style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.workers_table_text }}
                </span>
              </template>
              <template v-slot:item.address_represent="{ item }">
                <span style="width: 270px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.address_represent }}
                </span>
              </template>
              <template v-slot:item.subject="{ item }">
                <span>
                  {{ item.call_type === 'data_change' ? item.change_data_table_text : item.problem_table_text }}
                </span>
              </template>
              <template v-slot:item.status="{ item }">
                <span :class="`${getDispatcherStatus(item.status, 'color')}--text`" class="font-weight-medium">
                  {{ getDispatcherStatus(item.status) }}
                </span>
              </template>
              <template v-slot:item.icon="{ item }">
                <div class="d-flex align-center">
                  <v-avatar size="15" :color="getDispatcherPriority(item.priority, 'color')" />
                  <div style="margin-left: 4px; font-weight: 500">
                    {{ item.call_type === 'data_change' ? 'ЗД' : 'ВР'}}
                  </div>
                </div>
              </template>
              <template v-slot:item.debt_sum="{ item }">
                <span
                    class="font-weight-medium"
                    :class="[
                                {'error--text': item.debt_sum > 0},
                                {'success--text': item.debt_sum < 0}
                            ]"
                >
                  {{ item.debt_sum | formatToFixed }}
                </span>
              </template>
              <template v-slot:item.comment="{ item }">
                <span style="width: 170px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.comment }}
                </span>
              </template>
              <template v-slot:item.reaction_text="{ item }">
                <span style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.reaction_text }}
                </span>
              </template>
              <template v-slot:item.result_text="{ item }">
                <span style="width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block">
                  {{ item.result_text }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getCurrentDateTime, getCurrentDate, addDaysToStringDate} from "@/utils/icons";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_DISPATCHER_CALL, GET_ALL_DISPATCHER_CALL_BY_SEARCH} from "@/store/actions/dispatcher_call";
import {
    getDispatcherPriority,
    getDispatcherStatus,
    dispatcher_person_type_select,
    dispatcher_call_type_select,
    dispatcher_priority_select,
    dispatcher_status_select,
    debounce
} from '@/utils/icons'

export default {
  name: "DispatcherCallView",
  components: {
    MainModal: () => import('@/components/modal/MainModal.vue'),
    date_input: () => import('@/components/accounting/input/document_date_input.vue'),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    list_modal: () => import("@/components/accounting/list_modal/list_modal"),
  },
  computed: {
    ...mapGetters({
      items: 'getDispatcherCalls'
    })
  },
  data() {
    return {
      dispatcher_person_type_select,
      dispatcher_call_type_select,
      dispatcher_priority_select,
      dispatcher_status_select,
      selected: {},
      show_dialog: false,
      directories: [
        {
          list_name: 'NATURAL_PERSON_ELEMENTS',
          alternative_header_name: 'NATURAL_PERSON_FOR_DISPATCHER_ELEMENTS',
          list_group_name: '',
          hierarchy: false,
          list_title: 'Список виконавців\\працівників',
          element_title: 'Виконавці\\працівники',
          documents: false,
          icon: 'mdi-book-open-outline'
        },
        {
          list_name: 'DISPATCHER_PROBLEM_ELEMENTS',
          alternative_header_name: '',
          list_group_name: 'DISPATCHER_PROBLEM_GROUP',
          hierarchy: true,
          list_title: 'Список проблем диспетчерської',
          element_title: 'Проблеми диспетчерської',
          documents: false,
          icon: 'mdi-book-open-outline'
        }
      ],
      list_dialog: {
        dialog: false,
        name: '',
        group: '',
        title: '',
        element_title: '',
        hierarchy: false,
        document: false,
        full_screen: false,
        hide_accounting: false
      },
      tableHeader: [
        { text: '', value: 'icon', width: 36 },
        { text: 'Дата', value: 'call_date', width: 90},
        { text: 'Адреса', value: 'address_represent', width: 302},
        { text: 'Виклик від', value: 'flat_owner', width: 182},
        { text: 'Проблема/Суть', value: 'subject', width: 302},
        { text: 'Виконавець', value: 'workers_table_text', width: 182},
        { text: 'Статус', value: 'status', width: 120},
        { text: 'Борг', value: 'debt_sum', width: 100},
        { text: 'Коментар', value: 'comment', width: 202},
        { text: 'Реакція заявника', value: 'reaction_text', width: 202},
        { text: 'Результат виконання', value: 'result_text', width: 202},
      ],
      search_text: null,
      filters: {
        date_from: null,
        date_to: null,
        person_types: [],
        call_types: [],
        priorities: [],
        statuses: [],
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        street_id: null,
        building_id: null,
        entrance: null,
        floor: null,
        building_number: null,
        flat_number: null,
        show_filter: false
      },
      filters_copy: {},
      debounceFn: null,
    }
  },
  methods: {
    ...mapActions({
      fetchItems: GET_ALL_DISPATCHER_CALL,
      fetchItemsBySearch: GET_ALL_DISPATCHER_CALL_BY_SEARCH
    }),
    openListDialog(list_name, list_group_name, hierarchy, title,
                   element_title, document = false, full_screen = false,
                   hide_accounting = false) {
      if (!list_name && !list_group_name) {
        this.list_dialog.dialog = false
        this.list_dialog.name = ''
        this.list_dialog.group = ''
        this.list_dialog.hierarchy = false
        this.list_dialog.title = ''
        this.list_dialog.element_title = ''
        this.list_dialog.document = false
        this.list_dialog.full_screen = false
        this.list_dialog.hide_accounting = false
      }
      this.list_dialog.name = list_name
      this.list_dialog.group = list_group_name
      this.list_dialog.dialog = true
      this.list_dialog.hierarchy = hierarchy
      this.list_dialog.title = title
      this.list_dialog.element_title = element_title
      this.list_dialog.document = document
      this.list_dialog.full_screen = full_screen
      this.list_dialog.hide_accounting = hide_accounting
    },
    closeListDialog() {
      this.list_dialog.name = ''
      this.list_dialog.dialog = false
      this.list_dialog.group = ''
      this.list_dialog.hierarchy = false
      this.list_dialog.title = ''
      this.list_dialog.element_title = ''
      this.list_dialog.document = false
      this.list_dialog.full_screen = false
    },
    getDispatcherPriority,
    getDispatcherStatus,
    onItemClick(payload) {
      this.selected = JSON.parse(JSON.stringify(payload))
      this.show_dialog = true
    },
    openFilterDialog() {
      this.filters_copy = JSON.parse(JSON.stringify(this.filters))
      this.filters.show_filter = true
    },
    acceptFilter() {
      this.filters = JSON.parse(JSON.stringify(this.filters_copy))
      this.fetchItems(this.filters)
          .finally(() => this.filters.show_filter = false)
    },
    closeModal() {
      this.filters.show_filter = false
    },
    clearFilters() {
      this.filters_copy.date_from = null
      this.filters_copy.date_to = null
      this.filters_copy.person_types = []
      this.filters_copy.call_types = []
      this.filters_copy.priorities = []
      this.filters_copy.statuses = []
      this.filters_copy.amalgamated_hromada_id = null
      this.filters_copy.city_id = null
      this.filters_copy.city_area_id = null
      this.filters_copy.street_id = null
      this.filters_copy.building_id = null
      this.filters_copy.entrance = null
      this.filters_copy.floor = null
      this.filters_copy.building_number = null
      this.filters_copy.flat_number = null

      const current_date = getCurrentDate()
      this.filters_copy.date_from = `${addDaysToStringDate(current_date, -5)}T00:00:00`
      this.filters_copy.date_to = `${current_date}T23:59:59`
    },
    openCreateDialog() {
      this.selected = {}
      this.selected.call_date = getCurrentDateTime()
      this.selected.call_type = 'do_work'
      this.selected.person_type = 'with_address'
      this.selected.priority = 'medium'
      this.selected.status = 'registered'

      this.show_dialog = true
    },
    afterModalClose(payload) {
      this.show_dialog = false
      this.selected = {}

      if (payload) {
        if (payload.payload) {
          if (payload.payload.fetch) {
            this.fetchItems(this.filters)
          }
        }
      }

    },
    doSearch() {
      this.debounceFn()
    },
    searchByText() {
      this.$nextTick(() => {
        const e = this.search_text

        if (e && e.length >= 2) {
          this.fetchItemsBySearch({search_text: e})
        }

        if (!e) {
          this.fetchItems(this.filters)
        }
      })
    },
    clearSearch() {
      this.search_text = null

      this.fetchItems(this.filters)
    }
  },
  created() {
    const current_date = getCurrentDate()
    this.filters.date_from = `${addDaysToStringDate(current_date, -5)}T00:00:00`
    this.filters.date_to = `${current_date}T23:59:59`
    this.fetchItems(this.filters)
    this.debounceFn = debounce(() => this.searchByText(), 800)
  }
}
</script>

<style scoped lang="scss">
  .dispatcher-call-table {
    height: 100%;

    &:deep(.v-data-table__wrapper) {
      height: calc(100% - 70px);

      tbody {
        tr {
          td {
            font-size: .815rem;
          }
        }
      }
    }
    &:deep(.v-data-footer) {
      height: 70px;
    }
  }
</style>